<template>
  <Dialog
    v-model:visible="displayModal"
    :breakpoints="{'1300px': '100vw'}" :style="{width: '60vw'}"
    :modal="true"
    :closeOnEscape="true"
    :dismissableMask="true"
    @hide="onCloseModal"
  >
    <template #header>
      <div style="color:#1c3faa;">
        <h4>
          <i class="pi pi-pencil" style="font-size: 1rem;margin-right:10px"></i>
          <strong style="font-size:20px">Asignación Lotes</strong>
        </h4>
      </div>
    </template>
    <div class="grid grid-cols-7 text-xxs bg-gray-300 rounded p-2">
      <div class="flex flex-col col-span-4">
        <div class="font-bold">
          Código Mx: <span class="font-light">{{ dataModalLotes.ItemName }}</span>
        </div>
        <div class="font-bold">
          Nombre Mx: <span class="font-light">{{ dataModalLotes.ItemCode }}</span>
        </div>
      </div>
      <div class="flex flex-col">
        <strong> Cantidad en Inventario</strong>
        <span>{{ $h.formatNumber(BatchNumbers.reduce((acc, val) => acc + val.OnHandQty, 0)) }}</span>
      </div>
      <div class="flex flex-col">
        <strong> Cantidad en Documento</strong>
        <span>{{ $h.formatNumber(dataModalLotes.Quantity) }}</span>
      </div>
      <div class="flex flex-col">
        <strong> Cantidad Asignada</strong>
        <span>{{ $h.formatNumber(LotesAsignados.reduce((acc, val) => acc + val.Quantity, 0)) }}</span>
      </div>
    </div>
    <div class="grid grid-cols-7 text-xxs rounded gap-2">
      <div class="col-span-4 flex align-items-center mt-4">
        <Dropdown
          v-model="loteTemp.DistNumber"
          class="rounded-xs border-gray-300 w-full text-xs p-inputtext-xs"
          @click="$h.xxsInput"
          panelClass="text-xxs"
          :optionLabel="labelBatchNumbers"
          optionValue="DistNumber"
          :options="loteDisponible"
          @change="OnChangeLote"
          placeholder="Seleccione un lote..."
        />
      </div>
      <div class="flex align-items-center mt-4">
        <input :disabled="true"
               style="padding: 0.30rem;"
               v-model="loteTemp.ExpDate"
               class="p-button-secondary border rounded-md p-inputtext-xs w-full"
               type="date">
      </div>
      <div class="flex align-items-center mt-4">
          <span class="p-input-icon-left">
            <InputNumber :step="1" class="p-inputtext-xs " inputId="minmax"
                         showButtons buttonLayout="horizontal" :min="1"
                         v-model="loteTemp.Quantity" :max="loteTemp.OnHandQty"
                         decrementButtonClass="bg-blue-800" incrementButtonClass="bg-blue-800"
                         incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"/>
          </span>
      </div>
      <div class="flex justify-end align-items-center mt-4">
          <span class="p-input-icon-left">
            <Button icon="pi pi-plus" label="Agregar Lote" @click="addBatchNumber"
                    :disabled="dataModalLotes.Quantity === LotesAsignados.reduce((acc, val) => acc + val.Quantity, 0)"
                    class="p-button-outlined p-button-success p-button-xs"/>
          </span>
      </div>
    </div>
    <div class="pt-4">
      <DataTable
        :value="LotesAsignados"
        class="p-datatable-sm text-xxs"
        showGridlines
        dataKey="id"
        responsiveLayout="scroll"
        :scrollable="true"
        scrollHeight="500px"
      >
        <template #empty>
          No hay datos para mostrar
        </template>
        <Column field="BatchNumber" header="Lotes" headerStyle="justify-content: center;" style="min-width:30rem"
                bodyStyle="justify-content: center;text-align: center">
          <template #body="{data}">
            <div>
              <span v-html="infoLotes(data)"></span>
            </div>
          </template>
        </Column>
        <Column field="Quantity" header="Cantidad" headerStyle="justify-content: center;" style="width:5rem"
                bodyStyle="text-align: center;justify-content: center">
          <template #body="{data}">
            <div class="flex justify-center">
              {{ data.Quantity }}
            </div>
          </template>
        </Column>
        <Column field="ExpDate" header="Fecha Vencimiento" headerStyle="justify-content: center;"
                style="min-width:10rem"
                bodyStyle="justify-content: center;text-align: center">
          <template #body="{data}">
            <div>
              {{ data.ExpDate }}
            </div>
          </template>
        </Column>
        <Column field="Ubicacion" header="Ubicación" headerStyle="justify-content: center;" style="min-width:10rem"
                bodyStyle="justify-content: center;text-align: center">
          <template #body="{data}">
            <div>
              {{ data.Ubicacion }}
            </div>
          </template>
        </Column>
        <Column field="" header="Acciones" headerStyle="justify-content: center;" style="min-width:10rem"
                bodyStyle="justify-content: center;text-align: center">
          <template #body="slotProps">
            <Button icon="pi pi-trash" v-tooltip.top="'Eliminar lotes'"
                    iconClass="text-xl"
                    @click="deleteLine(slotProps)"
                    class="p-button-text p-button-danger">
            </Button>
          </template>
        </Column>
      </DataTable>
    </div>
    <div class="flex justify-center mt-4">
      <Button icon="pi pi-check-square" label="Confirmar" @click="confirmLine" class="p-button-outlined p-button-xs"/>
    </div>
  </Dialog>
</template>

<script>
import { ref, reactive, computed } from 'vue'
import Swal from 'sweetalert2'

export default {
  name: 'modalAsignarLotes',
  setup () {
    const BatchNumbers = ref([])
    const LotesAsignados = ref([])
    const displayModal = ref(false)
    const dataModalLotes = ref([])
    const loteTemp = reactive({
      Quantity: null,
      DistNumber: null,
      ExpDate: null,
      OnHandQty: null
    })
    const loteDisponible = computed(() => {
      if (!BatchNumbers.value) return []
      return BatchNumbers.value.filter((x) => x.visible)
    })
    const infoLotes = (data) => {
      const findLote = BatchNumbers.value.find((x) => x.DistNumber === data.DistNumber)
      return `<strong>Lote:</strong> ${findLote.DistNumber} - <strong>Fecha Vencimiento:</strong> ${findLote.ExpDate} - <strong>Ubicación:</strong> ${findLote.Ubicacion}`
    }
    const openModal = (data, dataMx) => {
      BatchNumbers.value = data
      dataModalLotes.value = dataMx
      LotesAsignados.value = dataMx.lotes
      displayModal.value = true
    }
    const addBatchNumber = () => {
      if (!loteTemp.DistNumber) {
        Swal.fire({
          icon: 'info',
          text: 'Por favor seleccione un lote e inténtelo de nuevo.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3500,
          timerProgressBar: true
        })
        return
      }
      if (loteTemp.Quantity > dataModalLotes.value.Quantity) {
        Swal.fire({
          icon: 'info',
          text: 'El valor asignado para el lote supera la cantidad máxima del documento.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3500,
          timerProgressBar: true
        })
        return
      }
      if ((LotesAsignados.value.reduce((acc, val) => acc + val.Quantity, 0) + loteTemp.Quantity) > dataModalLotes.value.Quantity) {
        Swal.fire({
          icon: 'info',
          text: 'El valor asignado más la sumatoria de las cantidades asignadas para el lote superan la cantidad máxima del documento.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3500,
          timerProgressBar: true
        })
        return
      }
      const index = BatchNumbers.value.findIndex((x) => x.DistNumber === loteTemp.DistNumber)
      LotesAsignados.value.push({
        Quantity: loteTemp.Quantity,
        DistNumber: loteTemp.DistNumber,
        ExpDate: loteTemp.ExpDate,
        AbsEntry: BatchNumbers.value[index].AbsEntry,
        Ubicacion: BatchNumbers.value[index].Ubicacion
      })
      BatchNumbers.value[index].visible = false
      loteTemp.DistNumber = null
      loteTemp.Quantity = null
      loteTemp.ExpDate = null
    }
    const confirmLine = () => {
      displayModal.value = false
    }
    const deleteLine = ({ index, data }) => {
      Swal.fire({
        icon: 'question',
        title: 'Esta seguro/a?',
        text: '¿Desea eliminar este lote de la asignación?',
        showConfirmButton: true,
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: 'Si, eliminar',
        cancelButtonText: 'No, cancelar'
      }).then(({ value }) => {
        if (value) {
          const findIndex = BatchNumbers.value.findIndex((x) => x.DistNumber === data.DistNumber)
          BatchNumbers.value[findIndex].visible = true
          LotesAsignados.value.splice(index, 1)
          Swal.fire({
            icon: 'success',
            title: 'Éxito',
            text: 'Lote eliminado correctamente',
            showConfirmButton: false,
            timer: 2500,
            timerProgressBar: true
          })
        }
      })
    }
    const onCloseModal = () => {
      loteTemp.Quantity = null
      loteTemp.DistNumber = null
      loteTemp.ExpDate = null
    }
    const labelBatchNumbers = (option) => {
      return `Lote: ${option.DistNumber} - Fecha Vencimiento: ${option.ExpDate} - Cantidad: ${option.OnHandQty} - Ubicación: ${option.Ubicacion}`
    }
    const OnChangeLote = ({ value }) => {
      const findLote = BatchNumbers.value.find((x) => x.DistNumber === value)
      loteTemp.ExpDate = findLote.ExpDate
      loteTemp.OnHandQty = findLote.OnHandQty
    }
    return {
      displayModal,
      openModal,
      BatchNumbers,
      confirmLine,
      LotesAsignados,
      addBatchNumber,
      loteTemp,
      dataModalLotes,
      OnChangeLote,
      loteDisponible,
      deleteLine,
      infoLotes,
      labelBatchNumbers,
      onCloseModal
    }
  }
}
</script>

<style scoped>
::v-deep(.p-column-header-content) {
  justify-content: center;
}

::v-deep(.p-inputnumber-input) {
  width: 3rem;
}

::v-deep(.p-inputnumber-buttons-horizontal) {
  justify-content: center;
}
</style>
